import React, { useState, useEffect } from 'react';
import {
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  Checkbox
} from '@mui/material';
import DateTimePicker from 'react-datetime-picker';
import { useGetUserData } from '@hooks/useGetUserData';
import { useGetDealSummaryAdmin } from '@hooks/useGetDealSummary';
import { useUpdateDeal } from '@hooks/useUpdateDeal';
import { useGetAIPostText } from '@hooks/useAI';
import { useSnackbar } from '@hooks/useSnackbar';
import { useChangeDealLists } from '@hooks/useDealLists';
import AddBannedTerm from '@components/AddBannedTerm';
import Loading from '@components/Loading';
import SeeStatsContent from '@components/AdminActions/components/SeeStatsContent';
import {
  parseCountFromString,
  qtyTermMap,
  AdminButtonProps
} from '@components/AdminActions/utils';
import SelectCategory from '@components/AdminActions/components/SelectCategory';
import { removeParagraphIndentation } from '@utils/index';
import ItemDealLists from '../DealListChanger/components/ItemDealLists';
import StrategyIds from './components/StrategyIds';

const ChangeInfoLogic = ({
  ASIN,
  onCloseCallback = () => {}
}: AdminButtonProps) => {
  const { showMessage } = useSnackbar();
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [sponsored, setSponsored] = useState(false);
  const [sponsorshipEnds, setSponsorshipEnds] = useState<Date | null>(null);
  const [sponsoredLink, setSponsoredLink] = useState('');
  const [brand, setBrand] = useState('');
  const [dateActive, setDateActive] = useState<Date>(new Date());
  const [qty, setQty] = useState<number | string>('');
  const [finalPriceOverride, setFinalPriceOverride] = useState<number | string>(
    ''
  );
  const [qtyTerm, setQtyTerm] = useState('');
  const [strategyId, setStrategyId] = useState('');
  const [dealThreshold, setDealThreshold] = useState<number | string>(0);
  const [postText, setPostText] = useState('');
  const { mutate: changeDealLists } = useChangeDealLists();

  const [selectedDealListItems, setSelectedDealListItems] = useState<number[]>(
    []
  );
  const {
    data: dealSummary,
    refetch,
    isLoading
  } = useGetDealSummaryAdmin(ASIN);
  const { data: user } = useGetUserData();
  const { mutate: updateDeal, isLoading: isUpdating } = useUpdateDeal();
  const { mutate: getAIPostText, isLoading: isLoadingAIPostText } =
    useGetAIPostText();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (dealSummary?.title) {
      setTitle(dealSummary.title);
    }
    if (dealSummary?.brand) {
      setBrand(dealSummary.brand);
    }

    if (dealSummary?.dateActive) {
      setDateActive(new Date(dealSummary.dateActive));
    }

    if (dealSummary?.strategyId) {
      setStrategyId(dealSummary.strategyId);
    }

    if (dealSummary?.dealThreshold) {
      setDealThreshold(dealSummary.dealThreshold);
    }
    if (dealSummary?.s) {
      setSponsored(dealSummary.s);
    }

    if (dealSummary?.sEnds) {
      setSponsorshipEnds(new Date(dealSummary.sEnds));
    }

    if (dealSummary?.sLink) {
      setSponsoredLink(dealSummary.sLink);
    }

    if (dealSummary?.category) {
      setCategory(dealSummary.category);
    } else {
      setCategory('');
    }

    if (dealSummary?.finalPriceOverride) {
      setFinalPriceOverride(dealSummary.finalPriceOverride);
    }

    if (dealSummary?.qty) {
      setQty(dealSummary.qty);
    } else if (parseCountFromString(dealSummary?.title) !== null) {
      setQty(parseCountFromString(dealSummary?.title || ''));
    }

    if (dealSummary?.qtyTerm) {
      setQtyTerm(dealSummary.qtyTerm);
    } else {
      qtyTermMap.forEach(({ search, term }) => {
        if (
          dealSummary?.title?.toLowerCase().includes(search.toLowerCase()) &&
          !qtyTerm
        ) {
          setQtyTerm(term.trim());
        }
      });
    }
    if (dealSummary?.postText) {
      setPostText(dealSummary.postText);
    }
  }, [dealSummary, setPostText]);

  const handleModalClose = () => {
    onCloseCallback();
  };

  if (!user?.isAdmin || !ASIN) {
    return null;
  }

  const renderUpdateButton = (shouldClose: boolean = true) => {
    return (
      <Button
        disabled={
          isUpdating ||
          title === '' ||
          dealThreshold === 0 ||
          parseFloat(`${dealThreshold}`) < 0.01
        }
        sx={{
          marginLeft: '8px'
        }}
        onClick={() => {
          const updatePayload: {
            title: string;
            brand: string;
            deal_threshold: number;
            qty?: number;
            qty_term?: string | null;
            post_text?: string | null;
            date_active: Date;
            category: string;
            sponsored: boolean;
            sponsorship_ends: Date | null;
            sponsored_link?: string;
            final_price_override?: number;
            strategy_id?: string;
          } = {
            title: title.trim(),
            brand,
            deal_threshold: parseFloat(`${dealThreshold}`),
            post_text: postText.trim(),
            date_active: dateActive,
            category,
            sponsored,
            sponsorship_ends: sponsorshipEnds,
            strategy_id: strategyId || '',
            sponsored_link: sponsoredLink.length === 0 ? null : sponsoredLink,
            final_price_override: Number.isNaN(
              parseFloat(`${finalPriceOverride}`)
            )
              ? null
              : parseFloat(`${finalPriceOverride}`)
          };

          if (qty !== '' && qtyTerm !== '') {
            updatePayload.qty = parseInt(`${qty}`, 10);
            updatePayload.qty_term = qtyTerm;
          } else {
            updatePayload.qty = null;
            updatePayload.qty_term = null;
          }

          updateDeal(
            {
              update: {
                ...updatePayload
              },
              ASIN,
              slug: dealSummary?.slug || ''
            },
            {
              onError: () => {
                // eslint-disable-next-line no-alert
                alert('An error happened, check the logs');
              },
              onSuccess: () => {
                changeDealLists(
                  {
                    ASIN,
                    dealListIds: selectedDealListItems
                  },
                  {
                    onSuccess: () => {
                      if (shouldClose) {
                        handleModalClose();
                      }
                    },
                    onError: () => {
                      showMessage('Error saving deal lists');
                    }
                  }
                );
              }
            }
          );
        }}
        variant={shouldClose ? 'contained' : 'outlined'}
        color={shouldClose ? 'primary' : 'secondary'}
      >
        {isUpdating && !shouldClose ? 'Updating...' : 'Update'}
      </Button>
    );
  };

  return (
    <>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <Box>
            <Box marginBottom="20px" />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  multiline
                  rows={4}
                  id="title"
                  label="Title"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
                <Box marginTop="12px">
                  <TextField
                    id="brand"
                    label="Brand"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={brand}
                    onChange={(e) => {
                      setBrand(e.target.value);
                    }}
                  />
                  <Box display="flex">
                    <Box display="inline-flex">
                      <AddBannedTerm
                        newTermCreate={{
                          term: (brand || '').toLowerCase(),
                          type: 'BANNED_AUTO_ADD_BRANDS'
                        }}
                        showLabel
                        blockingLabel="Block Brand for auto-add"
                        editingLabel="Edit Blocked Brand for auto-add"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box marginTop="12px">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="deal threshold"
                        label="Deal Threshold"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={dealThreshold}
                        onChange={(e) => {
                          if (e.target.value) {
                            setDealThreshold(parseFloat(e.target.value));
                          } else {
                            setDealThreshold('');
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <Button
                          onClick={() => {
                            setDealThreshold(
                              (dealSummary?.finalPrice + 0.01).toFixed(2) || 0
                            );
                          }}
                          variant="outlined"
                        >
                          Set current price
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box marginTop="12px">
                      <TextField
                        id="qty"
                        label="QTY"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={qty}
                        onChange={(e) => {
                          if (e.target.value) {
                            setQty(parseInt(e.target.value, 10));
                          } else {
                            setQty('');
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box marginTop="12px">
                      <TextField
                        id="qty term"
                        label="QTY Term"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={qtyTerm}
                        onChange={(e) => {
                          setQtyTerm(e.target.value);
                        }}
                        inputProps={{
                          autoCapitalize: 'none'
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box marginBottom="8px">
                  <TextField
                    multiline
                    rows={
                      removeParagraphIndentation(postText)?.length < 10 ? 1 : 12
                    }
                    id="post text"
                    label="Post Text"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={removeParagraphIndentation(postText)}
                    onChange={(e) => {
                      setPostText(e.target.value);
                    }}
                  />
                  <Box marginTop="12px">
                    <Button
                      onClick={() => {
                        getAIPostText(
                          {
                            ASIN
                          },
                          {
                            onSuccess: ({ suggestion }) => {
                              setPostText(suggestion);
                              showMessage('Suggestion applied');
                            }
                          }
                        );
                      }}
                    >
                      {isLoadingAIPostText ? 'Loading...' : 'Get AI Post Text'}
                    </Button>
                    {renderUpdateButton(false)}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <StrategyIds
              strategyId={strategyId}
              onChangeStrategyId={setStrategyId}
            />
            {ASIN && (
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography variant="overline">
                      Date first active
                    </Typography>
                    <Box>
                      <DateTimePicker
                        clearIcon={null}
                        onChange={setDateActive}
                        value={dateActive}
                      />
                      <Button
                        onClick={() => {
                          setDateActive(
                            new Date(new Date().getTime() - 10 * 60000)
                          );
                        }}
                      >
                        10 mins ago
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography variant="overline">Sponsored</Typography>
                    <Box>
                      <Checkbox
                        checked={sponsored}
                        onChange={() => setSponsored(!sponsored)}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography variant="overline">Sponsorship Ends</Typography>
                    <Box>
                      <DateTimePicker
                        onChange={setSponsorshipEnds}
                        value={sponsorshipEnds}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <TextField
                      id="sponsored link"
                      label="Sponsored Link"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={sponsoredLink}
                      onChange={(e) => {
                        setSponsoredLink(e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      marginLeft: { xs: '0px', md: '8px' }
                    }}
                  >
                    <TextField
                      id="final price override"
                      label="Final Price Override"
                      type="text"
                      variant="outlined"
                      value={finalPriceOverride}
                      onChange={(e) => {
                        if (e.target.value) {
                          setFinalPriceOverride(
                            !Number.isNaN(parseFloat(e.target.value))
                              ? e.target.value
                              : ''
                          );
                        } else {
                          setFinalPriceOverride('');
                        }
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            {ASIN && (
              <ItemDealLists
                onChange={(values) => setSelectedDealListItems(values)}
                ASIN={ASIN}
              />
            )}
            {ASIN && (
              <SelectCategory category={category} setCategory={setCategory} />
            )}
            <SeeStatsContent ASIN={ASIN} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={isUpdating} onClick={handleModalClose}>
          Cancel
        </Button>
        {renderUpdateButton(true)}
      </DialogActions>
    </>
  );
};

export default ChangeInfoLogic;
